import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { DigitalWallets } from '@latitude/digital-wallets';

import Layout from '@/components/layout';
import { ImportantInformation } from '@latitude/important-information';
import FilterList from '@/components/FilterList/FilterList';
import AdditionalCardsBranded from '@/components/AdditionalCardsBranded/AdditionalCardsBranded';
import { Box } from '@/components/Box/Box';

import PageData from '../../data/pages/credit-cards/index.json';
import { COLOR, BREAKPOINT } from '../../utils/constants';
import BRANDED_CARD_DATA from '../../data/pages/credit-cards/index-branded.json';
import goPlatinumImage from '../../images/hero/go-platinum-cashback.webp';
import balanceTransferImage from '../../images/hero/balance-transfer-carousel.webp';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { NotificationBanner } from '@latitude/banner';
import CarouselHeader from '@/components/Carousel/carouselHeader';
import bonusRewards from './28-degrees/images/herobanner-bonus-latitude-rewards.webp'
import monthlyCardFee from './28-degrees/images/herobanner-monthly-card-fees.webp'
{/* TO BE REMOVED --- START */}
import StandaloneText from '@/components/lab-components/StandaloneText';
import PromoCodeHandler from '@/components/PromoCodeHandler';
{/* TO BE REMOVED --- END */}

const APPLY_URL = 'https://cards.latitudefinancial.com/28degrees/';
const APPLY_URL_LOW_RATE ='https://cards.latitudefinancial.com/low-rate'

function CreditCardsPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  console.log(state);
  /** - END - */

  const latitudeRewardsTitle = "Hero Banner Slide - Get $300 bonus Latitude Rewards"
  const monthlyCardFeeTitle= "Hero Banner Slide - Enjoy $0 monthly card fee"
  const latitudeLowRateRewardsTitle = "Get up to $300 in bonus Latitude Rewards12"
  const balanceTransferTitle = "Get 0% for 18 months on balance transfers13"
  
  const promoApplyURLRewards = PromoCodeHandler({
    baseApplyURL:APPLY_URL,
    defaultPromoCode: "2WPFA6DJ",
    defaultSourceCode: "IDAAC",
    storageKey: "latitude_28degree_rewards_source_code"
  });
  
  const promoApplyURLMonthlyfee= PromoCodeHandler({
    baseApplyURL:APPLY_URL,
    defaultPromoCode: "HHJEQCUU",
    defaultSourceCode: "IDAAC",
    storageKey: "latitude_28degree_monthly_source_code"
  });

  const promoApplyURLLatitudeRewards=PromoCodeHandler({
    baseApplyURL:APPLY_URL_LOW_RATE,
    defaultPromoCode: "GHYTSERT",
    defaultSourceCode: "IDAAB",
    storageKey: "latitude_lowrate_rewards_source_code" // Unique key section wise
  });

  const promoApplyURLBalanceTransfer= PromoCodeHandler({
    baseApplyURL:APPLY_URL_LOW_RATE,
    defaultPromoCode:"DFGVHJUY",
    defaultSourceCode:"IDAAA",
    storageKey:"latitude_lowrate_bt_source_code"
  })

  const HEADER_ITEMS = [
    {
      title: 'Get $300 Bonus Latitude Rewards<sup>5</sup>',
      text:'When you spend $1,000 or more on eligible transactions every statement period in your first 3 months from approval.',
      termsconds: 'T&Cs, monthly credit card fee, and other charges apply. New customers only, must be approved by 28 May 2025.​',
      headerImage: bonusRewards,
      urlFindOutMore: '',
      urlApplyNow: promoApplyURLRewards
    },
    {
      title:'Enjoy $0 monthly card fee<sup>6</sup>',
      text:'For your first 12 months from approval with Latitude 28° Global Platinum Mastercard credit card.​',
      termsconds: 'T&Cs and other charges apply. New customers only, must be approved by 28 May 2025.​',
      headerImage: monthlyCardFee,
      urlFindOutMore: '',
      urlApplyNow: promoApplyURLMonthlyfee
    },
    { 
      title:'Enjoy up to $300 credit back just by shopping!',
      text:'Spend $1500 a month in the first 6 months from approval with a Latitude GO Platinum Mastercard credit card<sup>4</sup>.Excludes long term Interest Free plans.​',
      termsconds: 'T&Cs, monthly credit card fee & other charges apply. New / approved applicants, by 20 March 2025.​',
      headerImage: goPlatinumImage,
      urlFindOutMore: '',
      urlApplyNow:'https://cards.latitudefinancial.com/go'
    },
    { 
      title:'Get 0% for 18 months on balance transfers',
      text:'With Latitude Low Rate Mastercard credit card. 3% balance transfer fee applies. T&Cs apply.​',
      termsconds: 'New customers only, must be approved by 28 Feb 2025. T&Cs, card fee and other charges apply.​',
      headerImage: balanceTransferImage,
      urlFindOutMore: '',
      urlApplyNow:promoApplyURLBalanceTransfer
    }
  ];
 //Manually append the dynamic promo url to the contentful
  if (state) {
    const slides = state.heroBannerData?.[0].slides;

    if (slides) {
      slides.forEach(slide => {
        switch (slide.name) {
          case latitudeRewardsTitle:
            slide.buttons[0].url = promoApplyURLRewards
            break;
          case monthlyCardFeeTitle:
            slide.buttons[0].url = promoApplyURLMonthlyfee
            break;
          case latitudeLowRateRewardsTitle:
            slide.buttons[0].url = promoApplyURLLatitudeRewards
            break;
          case balanceTransferTitle:
            slide.buttons[0].url = promoApplyURLBalanceTransfer
            break;
          
        }
      })
    }
  }

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/"
          />
          <title>Explore Flexible Credit Card Options | Apply With Latitude</title>
          <meta name="description" content="Discover Latitude's range of credit cards tailored to suit your lifestyle. From rewards to low fees, find the card that fits your needs today!" />
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <div css={`
            @media (max-width: 768px) {
              .pageHeader, .pageHeaderItemText , .pageHeaderItemText h1 {
                background: linear-gradient(128.6deg, #0146aa 0%, #0162ed 100%) !important ;
                color: #fff !important;
              }
            }`}>
            <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />
            </div> 
        )}

        {/* TO BE REMOVED --- START */}
        {state?.standaloneTextData?.[0] && (
          <StandaloneText {...state?.standaloneTextData?.[0]} />
        )}
        {/* TO BE REMOVED --- END */}

        {/* <FeeChangesCCSection
          heading="Notice of Variation to your credit card contract"
          body={
            <>
              Effective from 17 September 2024, we are changing the annual
              purchase interest rate for{' '}
              <strong>
                Latitude GO Mastercard, Latitude GO Platinum Mastercard,
                Latitude Gem Visa, Latitude 28&deg; Global Platinum Mastercard,
                Latitude Eco Mastercard, Latitude Mastercard
              </strong>{' '}
              and <strong>Latitude Infinity Rewards Visa</strong> from 27.49%
              p.a. to 27.99% p.a.
            </>
          }
          link="https://latitudefs.zendesk.com/hc/en-au/articles/5078660193041-Credit-Card-Pricing-Changes"
        /> */}

        <CardContainer
          isResponsive
          css={`
            margin-top: 30px;
          `}
        >
          <BrandedFilterList
            isBranded
            className="branded"
            data={BRANDED_CARD_DATA.cards}
            selectedItem={PageData.categoryList}
            css={`
          &&{
            "position:relative;
            z-index:3"
          }

          [class^='card-media__buttons'] a{
            width: auto;
            max-width: unset;
            padding-left: 15px;
            padding-right: 15px;
          }
          `}
          />
          <br />
        </CardContainer>

        <Box css="position:relative;">
          <AngleWhite css="margin-top: 30px; 
            @media (min-width: 2000px) {
              margin-top: 50px;
            }
            @media (min-width: 3000px) {
              margin-top: 100px;
            }"
          />
          <div css="position:relative; z-index:1; font-size: 13px">
            <AdditionalCardsBranded />
          </div>
        </Box>

        <DigitalWallets
          heading="Digital wallets"
          subheading="You can pay any way on your phone or wearable tech device with just a tap."
        />

        <ImportantInformation
          data={require('../../data/pages/credit-cards/index.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          css={`
            && {
              background-color: ${COLOR.GREY10};
            }
          `}
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
}

const CardContainer = styled(Box)`
  position: relative;
  margin: 0 auto;
`;

const BrandedFilterList = styled(FilterList)`
  .dropdown-container-fixed {
    // mobile version
    width: 100%;
    max-width: 400px;
    margin-left: 0;
  }

  .dropdown::-ms-expand {
    display: none;
  }

  .dropdown-container {
    // desktop version
    position: absolute;
    left: 0;
    display: block;
    width: 255px;
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    &:last-child {
      padding-top: 100px;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    && div.lfs-grid-layout div.lfs-branded-card-grid:nth-child(3) {
      margin-top: 0;
    }
    && div.lfs-grid-layout div.lfs-branded-card-grid:nth-child(5) {
      margin-top: -50px;
    }
  }
`;

const AngleWhite = styled.div`
  position: absolute;
  width: 100%;
  height: 300%;
  background-color: #fff;
  transform: skew(0deg, 4deg);
  transform-origin: top right;
`;

export default CreditCardsPage;
