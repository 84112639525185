import { useState, useEffect } from 'react';

const PromoCodeHandler = ({
  baseApplyURL,
  defaultPromoCode,
  defaultSourceCode,
  storageKey = 'source_code' // Default storage key
}) => {
  const [applyURL, setApplyURL] = useState(baseApplyURL);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const passedPromoCode = urlParams.get('promo_code');
    const passedSourceCode = urlParams.get('source_code');

    // Retrieve source code from localStorage 
    const sourceCode = passedSourceCode || localStorage.getItem(storageKey);

    // Update localStorage only if a new sourceCode is passed
    if (passedSourceCode) {
      localStorage.setItem(storageKey, passedSourceCode);
    }
    
    // Construct the final apply URL
    const updatedURL = new URL(baseApplyURL);
    updatedURL.searchParams.set('promo_code', defaultPromoCode);
    updatedURL.searchParams.set('source_code', sourceCode || defaultSourceCode);

    setApplyURL(updatedURL.toString());
  }, [baseApplyURL, defaultPromoCode, defaultSourceCode, storageKey]);

  return applyURL; // Returns the dynamically generated URL
};

export default PromoCodeHandler;

//sample snippet

// const promoApplyURLGoMastercardRewards = PromoCodeHandler({
//   baseApplyURL:APPLY_NOW_URL,
//   defaultPromoCode: "GHYTSERT",
//   defaultSourceCode: "IDAAB",
//   storageKey: "latitude_gomastercard_source_code" // Unique key section wise
// });

//Component will return the Url with params which has sourcecode and promocode
//the function should be added in the cta(apply url button).  ex: urlApplyNow: promoApplyURLGoMasterCardRewards(as url)